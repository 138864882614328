import axios from 'axios'
import store from '@/stores'
import router from '@/routers'
import {
  Toast
} from 'vant'

/* 创建实例 */
const service = axios.create({
  baseURL: "/", // 当前环境地址变量
  timeout: 100000, // 请求超时时间
  headers: { // 设置默认请求头
    'Content-Type': 'application/json;charset=UTF-8'
  },
})

/* 请求拦截 */
service.interceptors.request.use(
  config => {
    let token = localStorage.getItem('accessToken')
    if (token) {
      config.headers['Authorization'] = token // 如果有token则每个请求上都加上
    }
    store.commit('showLoading')
    return config
  },
  err => {
    store.commit('hideLoading')
    return Promise.reject(err)
  }
)

/* 响应拦截 */
service.interceptors.response.use(function (res) {
  store.commit('hideLoading')
  return res;
}, function (error) {
  store.commit('hideLoading')
  return Promise.reject(error);
});

/**
 * 
 * @param {*} method 请求方法
 * @param {*} url 请求路径
 * @param {*} data 请求参数
 * @returns 
 */
function Request(method, url, data) {
  data = method === 'get' || method === 'delete' ? {
    params: data
  } : data
  return new Promise((resolve, reject) => {
    service[method](url, data)
      .then(
        res => {
          if (res.data.code == -5 && router.history.current.path != '/mine/login') {
            Toast('登录过期,请重新登录');
            router.push("/mine/login");
            return
          }
          resolve(res.data)
        },
        err => {
          reject(err)
        }
      )
      .catch(err => {
        reject(err)
      })
  })
}
export default Request